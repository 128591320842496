.footer1 {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
    width: 100%; 
    padding: 20px; 
    background-color: rgb(240, 240, 240); 
    margin-top: 20px; 
    height: auto; 
    text-align: center; 
}

.footer1 h1 {
    font-size: 1.8rem; 
    margin-bottom: 10px; 
    color: #333; 
}

.footer1 p {
    font-size: 1rem; 
    margin-bottom: 20px;
    color: #666;
}

.footer1 img {
    width: 100%; 
    height: auto;
    max-height: 650px; 
    object-fit: cover; 
}

@media screen and (max-width: 768px) {
    .footer1 h1 {
        font-size: 1.5rem; 
    }

    .footer1 p {
        font-size: 0.9rem; 
    }
    .footer1 img {
        max-height: 300px; 
    }
}

@media screen and (max-width: 480px) {
    .footer1 h1 {
        font-size: 1.2rem; 
    }

    .footer1 p {
        font-size: 0.8rem; 
    }

    .footer1 img {
        max-height: 200px; 
    }
}
