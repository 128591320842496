.dropdowncomponent {
    position: relative;
}

.dropdowncomponent h3 {
    font-size: 15px;
    margin: 0px;
    padding: 0px;
}

.dropdowncomponent .s1 h3:hover {
    color: var(--col1);
    /* border-bottom: 1px solid var(--col1); */
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(270px, 1fr)); 
  gap: 5px; 
  /* padding: 10px;  */
  justify-content: center; /* Center items horizontally */
}

.category {
  /* border: 1px solid #ddd;  */
  /* padding: 10px; */
  /* border-radius: 12px; */
  text-align: left;
  background: linear-gradient(135deg, #f9f9f9, #ffffff); /* Soft gradient */
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1); */
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
}

.category:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 3px 6px 15px rgba(0, 0, 0, 0.15); /* More shadow on hover */
}

.dropdowncomponent .s1 {
    background-color: white;
    cursor: pointer;
}

.dropdowncomponent .s2 {
    position: absolute;
    z-index: 100;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    top: 55px;
    left: 20px;
    padding: 20px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.075);
    max-height: 400px;
    overflow-y: scroll;
}

.dropdowncomponent .s2::-webkit-scrollbar {
    width: 5px;
}

.dropdowncomponent .s2::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.dropdowncomponent .s2::-webkit-scrollbar-thumb {
    background: #888;
}

.dropdowncomponent .s2 .category {
    /* background-color: aqua; */
    width: 100%;
    padding: 5px 25px;
    color: rgb(89, 89, 89);
}


.dropdowncomponent .s2 .category h3 {
    font-size: 13px;
}

.dropdowncomponent .s2 .category:hover {
    background-color: rgb(229, 231, 235);
    color: black;
}


.dropdowncomponent .s2 .category h3:hover {
    color: black;
}


/* .dropdowncomponent .s1 .border{
    background-color: var(--col1);
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: -20px;
    z-index: 120;
} */

/* make border animated from left to right on load */


 .dropdowncomponent .s1 .border {
    background-color: var(--col1);
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: -20px;
     left: 0;
    animation: borderanimation 0.5s ease-in-out;
} 
/* .dropdowncomponent .s1 .border {
    background-color: var(--col1);
    height: 5px;
    width: auto; 
    position: absolute;
    bottom: -20px;
    left: 0;
    display: inline-block; 
    animation: borderanimation 0.5s ease-in-out;
} */

@keyframes borderanimation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}



.droptitle{
    display: flex;
    align-items: center;
    gap: 5px;
}
.droptitle:hover{
    color: #02B290;
}

.droptitle svg{
    width: 15px;
    height: 15px;
}

.stylenone h3{
    padding: 3px;
}

@media (max-width: 500px) {
    .category{
        max-width: 200px;
    }
    .category-grid {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for smaller screens */
      }
    .dropdowncomponent h3{
        padding: 5px 0px;
    }
    .stylenone h3{
        padding: 0px;
        word-break: break-all;
    }
    .dropdowncomponent .s2 .category{
        padding: 0px;
    }
    .padt{
        margin-top: -5px;
    }
    
}

.navItem {
    position: relative;
    display: flex;
    cursor: pointer;
}

.navItem::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #02B290;
    transition: width 0.3s ease;
}

.navItem:hover::before {
    width: 100%;
}

.navItem:hover .arrowicon {
    color: #02B290 !important;
}
