.cartitem{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    padding: 10px;
}

.cartitem .s1{
    width: 30%;
    aspect-ratio: 1/1;
    position: relative;
}

.cartitem .s1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.cartitem .s2{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
    padding: 0 10px;
}

.cartitem .s2 .qty{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.cartitem .s2 .qty button{
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background-color: white;
    box-shadow: 1px 1px 5px lightgrey;
    cursor: pointer;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartitem .s2 .qty button:hover{
    background-color: var(--col1);
    color: white;
}

.cartitem .s2 .qty p{
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin: 0px;
}

.cartitem .s3{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 5px;
}

.cartitem .s3 p{
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin: 0px;
}


.cartitem .removeitem{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.cartitem .removeitem button{
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: white;
    /* box-shadow: 1px 1px 5px lightgrey; */
    cursor: pointer;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.cartitem .removeitem button svg{
    color: black;
}
.ProdName{
    font-size: 13px;
    word-break: break-all;
}
/* .amount {
    display: inline-block;
    vertical-align: middle;
  } */
  
/* CartItem.css */

.amount {
    display: flex;
    align-items: center; /* Align items vertically */
  }
  
  .amount span {
    margin-right: 5px; /* Adjust the margin as needed */
  }
  
/* make it responsive */

