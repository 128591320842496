.OrderSuccessful {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.refund-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid grey; /* Added grey border */
    box-shadow: 0 4px 10px rgba(128, 128, 128, 0.5); /* Changed shadow to grey */
    z-index: 1000;
  }
  
  .popup-content {
    text-align: center;
  }

  .popup-content h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col1);
    margin-top: 10px;
    width: 100%;
  }
  
  .popup-content table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .popup-content th, .popup-content td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .popup-content th {
    background-color: #f4f4f4;
  }
  
  .popup-content button {
    margin-top: 10px;
    padding: 10px 15px;
    background: rgb(231, 46, 46);
    color: white;
    border: none;
    cursor: pointer;
  }
  
  
  .order-summary button {
    padding: 8px 12px;
    background-color: rgb(231, 46, 46);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s ease-in-out;
}

.order-summary button:hover {
    background-color: rgb(200, 40, 40);
}

.order-summary button:disabled {
    background-color: rgb(150, 50, 50); /* Duller red */
    cursor: not-allowed;
    opacity: 0.6; /* Reduce opacity to make it look inactive */
}

.order-summary .close {
    padding: 8px 12px;
    background-color: rgb(29, 163, 89);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.3s ease-in-out;
}

.order-summary .close:hover {
    background-color: rgb(20, 120, 70);
}

  
  .order-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensures it spans full width */
  }
  
  .order-summary h2 {
    margin: 0; /* Removes default margin */
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col1);
    margin-top: 10px;
    width: 100%;
  }

  


.confirmationcont {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 5px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    width: 50% !important; 
    height: 50vh !important; 
    max-width: 1000px; 
    max-height: 600px; 
    overflow-y: scroll;
}


.confirmationcont::-webkit-scrollbar {
    width: 8px; /* Reduced scrollbar width */
    height: 8px; /* Reduced scrollbar height */
    background-color: #f5f5f5;
}

.confirmationcont::-webkit-scrollbar-thumb {
    background-color: var(--col1);
    border-radius: 5px;
}


.confirmationcont .c1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.confirmationcont .c1 svg {
    width: 100px;
    height: 100px;
    color: var(--col1);
}

.confirmationcont .c1 h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col1);
    margin-top: 10px;
}

.confirmationcont .c2 h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col1);
    margin-top: 10px;
    width: 100%;
}

.confirmationcont .c2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
}

.confirmationcont .c2 div {
    display: flex;
    width: 45%;
    justify-content: space-between;
}

.confirmationcont .c2 div p {
    width: 45%;
}

.confirmationcont .c2 div p:first-child {
    font-weight: 300;
    color: var(--col2);
    font-size: 15px;
}

.confirmationcont .c2 div p:last-child {
    font-weight: 600;
    color: var(--col2);
    font-size: 15px;
}

.confirmationcont .c3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
}

.confirmationcont .c3 table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--col2);
    border-radius: 10px;
    margin-bottom: 20px;
}

.confirmationcont .c3 table tr {
    border: 1px solid var(--col2);

}

.confirmationcont .c3 table tr th {
    padding: 10px;
    border: 1px solid var(--col2);
    text-align: center;
}

.confirmationcont .c3 table tr td {
    padding: 10px;
    border: 1px solid var(--col2);
    text-align: center;
}

.confirmationcont .c3 .right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px 20px;
}

.confirmationcont .c3 .right div {
    display: flex;
    gap: 20px;
    min-width: 27%;
    justify-content: space-between;
}

.confirmationcont .c3 .right div p {
    font-weight: 300;
    color: black;
    font-size: 16px;
    width: 50%;
    text-align: center;
}




.totaltableleft {
    font-size: 18px;
    font-weight: 400;
}

.totaltableright {
    font-size: 21px;
    font-weight: 600;
    min-width: 100px;
}


@media screen and (max-width: 900px) {
    .confirmationcont {
        width: 100%;
        height: 100%;
        border-radius: 0px;
    }

    .confirmationcont .c2 div {
        width: 100%;
        justify-content: space-between;
    }

    .confirmationcont .c2 div p {
        width: 100%;
    }


    .confirmationcont .c3{
        padding: 5px;
    }
    .confirmationcont .c3 table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid var(--col2);
        border-radius: 10px;
        margin-bottom: 20px;
    }
    
    .confirmationcont .c3 table tr {
        border: 1px solid var(--col2);
    
    }
    
    .confirmationcont .c3 table tr th {
        padding: 2px;
        border: 1px solid var(--col2);
        text-align: center;
    }
    
    .confirmationcont .c3 table tr td {
        padding: 2px;
        border: 1px solid var(--col2);
        text-align: center;
        font-size: 10px;
        width: 20%;

    }
    

    .confirmationcont .c3 .right div {
        min-width: 100%;
        justify-content: space-between;
    }

    .confirmationcont .c3 .right div p {
        width: 100%;
    }
}


@media screen and (max-width: 400px) {
    .confirmationcont{
        padding: 0px;
    }
    .confirmationcont .c2 h2 {
        font-size: 1rem;
        font-weight: 600;
        color: var(--col1);
        margin-top: 10px;
        width: 100%;
    }
    .confirmationcont .c2 {
        padding: 10px;
        /* background-color: aqua; */
    }

    .confirmationcont .c2 div p {
        width: 50%;
        font-size: 10px;
    }

    .confirmationcont .c2 div p:first-child {
        font-weight: 300;
        color: var(--col2);
        font-size: 12px;
    }

    .confirmationcont .c2 div p:last-child {
        font-weight: 600;
        color: var(--col2);
        font-size: 12px;
        text-align: right;
    }
   
    
}