.bannerslider {
    width: 100%;
    max-width: 100vw;
}

.bannerslider .imagecont {
    background-color: rgb(255, 255, 255);
    display: flex;
    position: relative;
    width: 100%;
    min-height: max-content;
    padding-bottom: 20px;
}

.bannerslider .imagecont img {
   
    width: 100% !important;
    max-width: 100vw; /* Ensures it spans full viewport width */
    height: auto;
    object-fit: cover; /* Ensures it scales properly */
}

.bannerslider .imagecont .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}