.card-popup__content{
    background-color: white;
    width: 40%;
    height: 80%;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 20px;
    overflow-y: scroll;
}

.card-popup__content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
}

.card-popup__content::-webkit-scrollbar-thumb {
    background-color: var(--col1);
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.card-popup__content form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}


.card-popup__content form .s1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.card-popup__content form .s1 img {
    height: 50px;
    object-fit: contain;
    margin-bottom: 20px;
}

.card-popup__content form .s1 h1 {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 10px;
    color: black;
}

.card-popup__content form .s1 p {
    font-size: 17px;
    font-weight: 300;
    margin-bottom: 10px;
    color: grey;
}

.card-popup__content form .s1 p a {
    color: var(--col1);
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
}



.card-popup__content form .formcont {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.card-popup__content form .formcont input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 10px;
    outline: none;
}

.card-popup__content form .formcont input:focus {
    border: 1px solid var(--col1);
}

.card-popup__content form .formcont label {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 3px;
    color: grey;
}

.card-popup__content .formrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}



.card-popup__content .btn {
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--col1);
    color: #fff;
    font-size: 17px;
    font-weight: 300;
    cursor: pointer;
    padding: 15px;
    margin: 20px 0px;
    opacity: 0.8;
}

.card-popup__content .btn:hover {
    background-color: var(--col1);
    color: white;
    opacity: 1;
}