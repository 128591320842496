.cartcontainerout {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.cartcontainerin {
    width: 30%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    right: 0px;
    overflow: scroll;
    width: 520px;
    /* height: 500px; */
    padding: 20px;
}

.cart-popup__close-btn {
    background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  height: 30px; /* Adjust the button height to fit */
  width: 30px;  /* Ensure it has width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-popup__close-btn svg {
    color: black;
    width: 40px;
    height: 40px;
}


.cartcontainerin .c11 {
    position: fixed;
    top: 0;
    background-color: white; /* Optional: to ensure background is white */
    z-index: 1000; /* Ensure it's on top of other elements */
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center items */
    height: 50px; 
}

.cartcontainerin .c11 h1 {
    font-size: 22px;
    margin: 0;
    font-weight: 600;
    color: black;
    margin-right: 250px
}

.cartcontainerin .emptycart {
    display: flex;
    flex-direction: column;
    padding: 30px;
    justify-content: center;
    align-items: center;
    height: 60%;
}

.cartcontainerin .emptycart svg {
    width: 300px;
    height: 300px;
    margin-bottom: 20px;
    color: lightgrey;
}


.cartcontainerin .emptycart h1 {
    font-size: 25px;
    font-weight: 600;
    color: black;
    margin-bottom: 20px;
}

.cartcontainerin .emptycart p {
    font-size: 17px;
    font-weight: 300;
    color: grey;
}

.cartcontainerin .c3 {
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgrey;
    width: 100%;
    padding: 30px;
    height: 25%;
}

.cartcontainerin .c3 .c31 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cartcontainerin .c3 .c31 h1 {
    font-size: 20px;
    font-weight: 600;
    color: black;
}
.cartcontainerin .c3 span{
    font-size: 17px;
    font-weight: 600;
    color: white;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 8px;
    text-align: center;
    background-color: var(--col1);
}
.cartcontainerin .c3 p {
    font-size: 15px;
    font-weight: 300;
    color: grey;
    margin-bottom: 20px;
    width: 70%;
}

.cartcontainerin .c3 .checkoutbtn1 {
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--col1);
    color: #fff;
    font-size: 17px;
    font-weight: 300;
    cursor: pointer;
    padding: 15px;
    margin: 20px 0px;
    opacity: 0.5;
}

.cartcontainerin .c3 .checkoutbtn {
    width: 100%;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--col1);
    color: #fff;
    font-size: 17px;
    font-weight: 300;
    cursor: pointer;
    padding: 15px;
    margin: 20px 0px;
    opacity: 1;
}

.cartcontainerin .cartitems {
    min-height: 60%;
}

/* style the scrollbar */
.cartcontainerin::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: hwb(0 100% 0%);
}

.cartcontainerin::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 5px;
    border: 1px solid white;
}



/* make it responsive */

@media screen and (max-width: 1000px) {
    .cartcontainerin {
        width: 50%;
    }

    .cartcontainerin {
        min-height: 100vh;
    }
}

@media screen and (max-width: 768px) {
    .cartcontainerin {
        width: 100%;
    }

    .cartcontainerin .c3 .c31 h1 {
        font-size: 17px;
    }

    .cartcontainerin .c3 p {
        font-size: 15px;
    }

    .cartcontainerin .c3 .checkoutbtn1 {
        font-size: 15px;
    }

    .cartcontainerin .c3 .checkoutbtn {
        font-size: 15px;
    }

    .cartcontainerin .c1 h1 {
        font-size: 20px;
    }

    .cartcontainerin .emptycart h1 {
        font-size: 20px;
    }

    .cartcontainerin .emptycart p {
        font-size: 15px;
    }

    .cartcontainerin .c3 .c31 h1 {
        font-size: 17px;
    }


}

@media screen and (max-width: 500px) {
    .cartcontainerin .c3 .c31 h1 {
        font-size: 17px;
    }

    .cartcontainerin .c3 p {
        font-size: 15px;
    }

    .cartcontainerin .c3 .checkoutbtn1 {
        font-size: 15px;
    }

    .cartcontainerin .c3 .checkoutbtn {
        font-size: 15px;
    }

    .cartcontainerin .c1 h1 {
        font-size: 20px;
    }

    .cartcontainerin .emptycart h1 {
        font-size: 20px;
    }

    .cartcontainerin .emptycart p {
        font-size: 15px;
    }

    .cartcontainerin .c3 .c31 h1 {
        font-size: 17px;
    }


}