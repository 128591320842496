.categorysidebar .category img {
    width: 40px;
    height: 40px;
}
.categorysidebar{
    /* width: 20%; */
    width: 100%;
}
.categorysidebar h2{
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
    padding-bottom: 20px;
    /* border: 1px solid #ccc; */
}
.categorysidebar .categorysidebarin{
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    height: 1000px;
    overflow-y: scroll;
}

.categorysidebarin::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: #f5f5f5;
  }
  
.categorysidebarin::-webkit-scrollbar-thumb {
    background-color: var(--col3);
    border-radius: 5px;
  }

.categorysidebar .category {
    display: flex;
    align-items: center;
    border: 1px solid rgb(231,236,240);
    padding: 10px 10px;
    flex-wrap: wrap;
    gap: 10px;
}

.categorysidebar .category:hover{
    background-color: rgb(243, 246, 249);
    cursor: pointer;
}

.categorysidebar .category h3{
    /* margin:  0px 20px; */
    font-size: 15px;
    padding: 0px;
    /* text-transform: lowercase; */
    margin: 0px;
}




.categorysidebar .category{
    flex-direction: column;
    width: 100%;
}

.categorysidebar .category .s1{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    position: relative;
}

.categorysidebar .category .s1 .drop{position: absolute;
    right: 10px;
    cursor: pointer;
}

.categorysidebar .category .s1 .drop svg{
    width: 20px;
    height: 20px;
}

.categorysidebar .category  .s2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    /* padding-left: 20px; */
}

.categorysidebar .category  .s2 div{
    width: 40px;
    height: 40px;
}

.categorysidebar .category  .s2{
    font-size: 12px;
    margin: 0px;
    padding: 0px;
}


.catName {
    font-size: 16px !important;
}
@media screen and (max-width: 1200px) {
    .catName {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 950px) {
    .catName {
        font-size: 12px !important;
    }
}
@media screen and (max-width: 1100px) {
    .catName {
        font-size: 14px !important;
    }
    .categorysidebar .category {
        padding: 10px 5px;
    }

    .categorysidebar .category h3{
        /* margin:  0px 20px; */
        font-size: 12px;
        padding: 0px;
        /* text-transform: lowercase; */
        margin: 0px;
    }
}


@media  screen and (max-width: 800px) {
    .categorysidebar{
        display: none;
    }
}
/* Hide .accordion-button:after when there are no subcategories */
.accordion-item:not(.no-subcategories) .accordion-button:after {
    display: none;
  }
  