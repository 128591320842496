.returnorders {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    padding: 50px;
    position: relative;
}


.returnorders table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 5px;
    height: 100px;
    overflow: hidden;
    /* margin-top: 30px; */
}

.returnorders table tr {
    border-bottom: 1px solid rgb(207, 207, 207);
    /* border-radius: 5px; */
}

.returnorders table tr th {
    /* padding: 10px 20px; */
    /* border-radius: 5px; */
    background-color: rgb(246, 246, 246);
    color: grey;
}

.yourorders table tr td {
    /* padding: 10px 20px; */
    /* border-radius: 5px; */
    color: grey;

}

.returnorders table tr td button {
    /* padding: 5px 20px; */
    margin: 0px;
    font-size: 16px;
}

.returnorders table tbody {
    /* height: 200px; */
    overflow-y: scroll;
}