.add-new-address-out {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-address-in {
    width: 50%;
    height: 80vh;
    background-color: white;
    border-radius: 10px;
    position: relative;
    overflow: scroll;
  
}

.add-new-address-in form {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 0px auto;
    align-items: center;
    padding: 20px 0px;
    /* justify-content: center; */
}

.add-new-address-in form h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    width: 100%;
}

.add-new-address-in form .formcont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0px;
}

.add-new-address-in form .formcont1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    gap: 10px;
}

.add-new-address-in form .formcont1 #defaultcheck {
    margin: 0px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
}


.add-new-address-in form input {
    width: 100%;
    /* height: 40px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 12px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.add-new-address-in form input:focus {
    outline: none;
    border: 2px solid var(--col1);
}

.add-new-address-in form button {
    width: 100%;
    background-color: var(--col1);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    opacity: 0.3;
    cursor: pointer;
    padding: 15px 0px;
}

.add-new-address-in form button {
    opacity: 1;
    background-color: var(--col1);
    color: white;
}

.add-new-address-in form .formcont div {
    display: flex;
}
.error-msg {
    color: red;
  }
  
.add-new-address-in form .formcont div input {
    margin: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.add-new-address-in form .formcont div button {
    margin: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.add-new-address-in form label {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-bottom: 5px;
}


@media screen and (max-width: 768px) {
    .add-new-address-in {
        width: 80%;
    }
}

@media screen and (max-width: 468px) {
    .add-new-address-in {
        width: 95%;
    }
}