.extrapageout {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.extrapagein {
    width: 80%;
    align-self: center;
}

.extrapageout .c1 {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 40px 0px;
    margin-top: 20px;
}

.extrapageout .c1 h1 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
}

.extrapageout .c1 h2 {
    font-size: 23px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
}

.extrapageout .c1 h2 span {
    color: var(--col1);
}

.extrapageout .c1 p {
    font-size: 16px;
    font-weight: 400;
    color: rgb(83, 83, 83);
    margin-bottom: 10px;
}
.extrapageout .c1 span{
    color: black;
    font-size: 20px;
    font-weight: 600;
}
.extrapagein .images2,
.extrapagein .images3 {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.extrapagein .images2 img {
    width: 49%;
}

.extrapagein .images3 img {
    width: 33%;
}


/* contact */

.contactformout {
    width: 70%;
    align-self: center;
    background-color: white;
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    top: -50px;
    display: flex;
    justify-content: space-between;
}


    

.contactformleft {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contactformright {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.contactformright .formcont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0px;
}

.contactformright .formcont label {
    font-size: 14px;
    font-weight: 400;
    color: rgb(112, 112, 112);
    margin-bottom: 5px;
}

.contactformright .formcont input {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 400;
    color: rgb(83, 83, 83);
}

.contactformright .formcont input:focus {
    outline: none;
    border: 1px solid var(--col1);
}

.contactformright .formcont textarea {
    width: 100%;
    height: 150px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 400;
    color: rgb(83, 83, 83);
}

.contactformright .formcont textarea:focus {
    outline: none;
    border: 1px solid var(--col1);
}

.contactformright button {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: var(--col1);
    color: white;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.extrapageout .c2 {
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-self: center;
    margin: 50px 0px;
}

.extrapageout .c2 .c2in{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.extrapageout .c2 .c2in svg{
    width: 80px;
    height: 80px;
    color: rgb(165, 165, 165);
}

.extrapageout .c2 .c2in h1{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.extrapageout .c2 .c2in p{
    font-size: 14px;
    font-weight: 400;
    color: rgb(83, 83, 83);
    margin-bottom: 10px;
}

.extrapageout iframe{
    max-width: 100vw;
}

@media screen and (max-width: 1000px) {
    .contactformout {
        flex-direction: column;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }

    .contactformleft {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .contactformright {
        width:95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media screen and (max-width: 800px) {
    .extrapageout .c2{
        width: 90%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .extrapageout .c2 .c2in h1{
        font-size: 17px;
        font-weight: 600;
        color: #000;
        margin-bottom: 10px;
    }
    
    .extrapageout .c2 .c2in p{
        font-size: 14px;
        font-weight: 400;
        color: rgb(83, 83, 83);
        margin-bottom: 10px;
    }
    .extrapageout .c2 .c2in svg{
        width: 50px;
        height: 50px;
        color: rgb(165, 165, 165);
    }
}



@media screen and (max-width: 500px){
    .contactformout {
        width: 95%;
    }
    .extrapageout .c2{
        flex-direction: column;
        width: 90%;
        align-items: center;
        gap: 20px;

    }

    .extrapageout .c2 .c2in{
        width: 100%;
        gap: 20px;
        justify-content: flex-start;
    }

    .extrapageout .c2 .c2in svg{
        width: 30px;
        height: 30px;
        color: rgb(165, 165, 165);
    }
}