/* Main container for the return page */
.Return-container {
    max-width: 800px;
    margin: 10px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fdfdfd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Header styles */
  .header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Grid styles for displaying products */
  .return-grid {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr; /* Added space for checkbox */
    gap: 15px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .grid-header {
    font-weight: bold;
    display: contents; /* Keeps headers aligned with grid rows */
    text-align: center;
  }
  
  .grid-row {
    display: contents;
    text-align: center;
  }
  
  .grid-row span, .grid-header span {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .grid-row span {
    color: #333;
  }
  
  .grid-header span {
    background-color: #e0e0e0;
    font-size: 16px;
  }
  
  /* Buttons container */
  .buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .buttons button {
    padding: 12px 25px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .buttons button:first-of-type {
    background-color: #ff4d4d; /* Red for Cancel */
  }
  
  .buttons button:last-of-type {
    background-color: #28a745; /* Green for Proceed */
  }
  