@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
:root {
  --col1: rgb(2,178,144);
  --col2: gray;
  --col3: #cecece;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}


#dropdown-basic {
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
}

.goback{
  background-color: var(--col1);
  color: white;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  margin: 10px 0px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 100px;
}

.goback svg{
  margin-right: 5px;
  width: 15px;
  height: 15px;
}

.stylenone{
  text-decoration: none;
  color: black;
}


.mainbutton1 {
  background: none;
  border: 1px solid var(--col1);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1rem;
  cursor: pointer;
  color: var(--col1);
}

.mainbutton1 i {
  color: black; /* Ensures the icon color is black */
}

.status-open { color: blue; }
.status-confirmed { color: green; }
.status-payment-received { color: darkgreen; }
.status-picked { color: orange; }
.status-packed { color: darkorange; }
.status-rider-assigned { color: purple; }
.status-out-for-delivery { color: darkred; }
.status-delivered { color: green; font-weight: bold; }
.status-return { color: red; }
.status-refund { color: brown; }
.status-unknown { color: gray; }


.mainbutton1:hover {
  background-color: var(--col1);
  color: white;
  transform: scale(1.1);
  transition: all 0.6s ease-in-out;
}

.mainbutton1:hover i {
  color: white; /* Changes icon color to white on hover */
}


.mainhead1 {
  color: var(--col1);
  font-size: 35px;
  font-weight: 300;
  padding: 5px 10px;
  margin-top: 10px;
}


body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--col1);
  border-radius: 5px;
}

.mainhead2{
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
