.accountsettings{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 50px;
}


.accountsettings .form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
}

.accountsettings .form .form-group{
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 10px 0px;
}

.accountsettings .form .form-group label{
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    color: gray;
}

.accountsettings .form .form-group input{
    padding: 10px 20px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 5px;
    outline: none;
}

.accountsettings button{
    width: 160px;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: var(--col1);
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    
}

@media screen and (max-width: 768px){
    .accountsettings{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        padding: 10px;
    }
    
    .accountsettings .form .form-group{
        width: 100%;
    }

    .accountsettings button{
        width: 100%;
        height: fit-content;
        padding: 5px;
        font-size: 13px;
    }
}