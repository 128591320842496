.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd; 
    margin: 0 15px; 
  }
  
  .carousel-item img {
    max-width: 100%;
    object-fit: cover;
    border-radius:10px
  }
  .responsive-image {
    max-width: 100%;
    height: auto;
    width: 100%;
    max-height: 330px !important;
    min-height: 330px !important;
  }
  

  @media (max-width: 500px) {
    .responsive-image {
      max-height: 170px !important;
      min-height: 170px !important;

    }
}