.paymentpage_container {
  /* width: 900px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--col1);
}

.paymentpage_container label {
  margin-bottom: 3px;
}

.paymentpage_container .spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  border-width: .1em;
}

.paymentpage_container .success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.paymentpage_container .success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}

.paymentpage_container .success-msg .title {
  font-size: 25px;
  margin-top: 10px;
}