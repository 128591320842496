.useraddress {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* background-color: #f5f5f5; */
}

.useraddress .default{
    background-color: var(--col1);
    color: white;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
}

.addressin {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.addressin .addresscontainer {
    /* width: 330px; */
    width: 100%;
}


@media screen and (max-width: 680px) {
    .mainhead1 {
        font-size: 16px;
    }

    .addressin {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        padding: 10px;
    }

    .addressin .addresscontainer {
        width: 100%;
        min-height: 50px;
        height: min-content;
        font-size: 12px;
    }

    .addressin .addresscontainer p {
        font-size: 12px;
    }
}