.yourorders {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    padding: 50px;
    position: relative;
}

.yourorders table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 5px;
    height: 100px;
    overflow: hidden;
    /* margin-top: 30px; */
}

.tab-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 8px 16px;
    background: #c4c3c3;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background 0.3s, color 0.3s, box-shadow 0.3s;
}

/* Active Button */
.tab-button.active {
    background: #02B290; /* Updated active color */
    color: white;
}

/* Hover Effect */
.tab-button:hover {
    background: #02B290;  /* Single background color */
    color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}



  

.yourorders table tr {
    border-bottom: 1px solid rgb(207, 207, 207);
    /* border-radius: 5px; */
}

.yourorders table tr th {
    /* padding: 10px 20px; */
    /* border-radius: 5px; */
    background-color: rgb(246, 246, 246);
    color: grey;
}

.yourorders table tr td {
    /* padding: 10px 20px; */
    /* border-radius: 5px; */
    color: grey;

}

.yourorders table tr td button {
    /* padding: 5px 20px; */
    margin: 0px;
    font-size: 16px;
}

.yourorders table tbody {
    /* height: 200px; */
    overflow-y: scroll;
}

.yourorders .delivered {
    content: '';
    width: 10px;
    height: 10px;
    background-color: green;
    display: inline-block;
    border-radius: 50%;
    align-self: center;
    margin: auto;
    margin-right: 5px;
}

.yourorders .ontheway {
    content: '';
    width: 10px;
    height: 10px;
    background-color: orange;
    display: inline-block;
    border-radius: 50%;
    align-self: center;
    margin: auto;
    margin-right: 5px;
}

.yourorderscontent {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    overflow: hidden; */
}


.confirmationcont1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    width: 90%;
}

.confirmationcont1 .c1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.confirmationcont1 .c1 svg {
    width: 100px;
    height: 100px;
    color: var(--col2);
}

.confirmationcont1 .c1 h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col2);
    margin-top: 10px;
}

.confirmationcont1 .c2 h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--col2);
    margin-top: 10px;
    width: 100%;
}

.confirmationcont1 .c2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
}

.confirmationcont1 .c2 div {
    display: flex;
    width: 45%;
    justify-content: space-between;
}

.confirmationcont1 .c2 div p {
    width: 45%;

}

.confirmationcont1 .c2 div p:first-child {
    font-weight: 300;
    color: var(--col3);
    font-size: 15px;
}

.confirmationcont1 .c2 div p:last-child {
    font-weight: 600;
    color: var(--col3);
    font-size: 15px;
}

.confirmationcont1 .c3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
}

.confirmationcont1 .c3 table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--col3);
    border-radius: 10px;
    margin-bottom: 20px;
}

.confirmationcont1 .c3 table tr {
    border: 1px solid var(--col3);

}

.confirmationcont1 .c3 table tr th {
    padding: 10px;
    border: 1px solid var(--col3);
    text-align: center;
}

.confirmationcont1 .c3 table tr td {
    padding: 10px;
    border: 1px solid var(--col3);
    text-align: center;
}

.confirmationcont1 .c3 .c4 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px 20px;
}

.confirmationcont1 .c3 .c4 div {
    display: flex;
    gap: 20px;
    min-width: 20%;
    justify-content: space-between;
}

.confirmationcont1 .c3 .c4 div p {
    font-weight: 300;
    color: var(--col3);
    font-size: 25px;
}

@media (max-width: 1000px) {
    .yourorders {
        display: flex;
        flex-direction: column;
        align-items: none;
        /* justify-content: center; */
        width: 100%;
        height: 100%;
        padding: 0px;

    }
    .yourorders .mainhead2{
        text-align: center;
        margin: 10px 0px;
    }
    .yourorders table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid rgb(207, 207, 207);
        border-radius: 5px;
        overflow: scroll;
        margin: 0px;

    }

    .yourorders table tr {
        border-bottom: 1px solid rgb(207, 207, 207);
        border-radius: 5px;
    }

    .yourorders table tr th {
        padding: 5px;
        border-radius: 5px;
        background-color: rgb(246, 246, 246);
        color: grey;
        font-size: 12px;
    }

    .yourorders table tr td {
        padding: 5px;
        border-radius: 5px;
        color: grey;
        font-size: 12px;
    }

    .yourorders table tr td button {
        padding: 2px 5px;
        margin: 0px;
        font-size: 12px;
    }

    .yourorders table tbody {
        overflow-y: scroll;
    }

    .yourorders .delivered {
        content: '';
        width: 5px;
        height: 5px;
        background-color: green;
        display: inline-block;
        border-radius: 50%;
        align-self: center;
        margin: auto;
        margin-right: 2px;
    }

    .yourorders .ontheway {
        content: '';
        width: 5px;
        height: 5px;
        background-color: orange;
        display: inline-block;
        border-radius: 50%;
        align-self: center;
        margin: auto;
        margin-right: 2px;
    }
}

@media (max-width: 768px) {
    .yourorders table {
        overflow: scroll;
    }
}