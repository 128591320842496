
.product {
    display: flex;
    min-width: 200px;
    width: 200px; 
    height: 300px; 
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 0 10px; 
    position: relative;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.083);
}




.wishlistbtn, .wishlistbtn1{
    width: 20px; /* Adjusted width */
    height: 20px; /* Adjusted height */
    position: absolute;
    top: 5px; /* Adjusted top */
    right: 5px; /* Adjusted right */
}



.wishlistbtn svg{
    color: #5b5b5b;
}

.wishlistbtn svg:hover{
    color: var(--col1);
    cursor: pointer;
}

.wishlistbtn1 svg{
   fill: #ff78ae;
   color: #ff78ae;
}

.product .s1 {
    height: 65%;
    width: 100%;
    overflow: hidden;
    padding: 20px;
}

.product .s1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.5s ease;
}

.product .s1 img:hover {
    scale: 1.1;
}

.product .s2 {
    height: 20%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

}

.product h3 {
    font-size: 10px;
    font-weight: 600;
    margin: 0px;
    width: 90%;
}

.product h3 span {
    text-decoration: line-through;
    font-size: 15px;
    color: gray;
    margin-left: 5px;
}

.product .s2 p {
    font-size: 6px;
    font-weight: 500;
    margin: 0px;
    width: 90%;
}


.product .s3 {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px 0px;
}

.product .s3 p {
    font-size: 10px;
    font-weight: 500;
    margin: 0px;
    padding: 5px 0px;
    border-radius: 5px;
    color: #5b5b5b;
    width: 90%;
}

.product .addbtn {
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.product .addbtn svg {
    width: 25px;
    height: 25px;
    background-color: var(--col1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 5px;
    color: white;
    transition: all 0.5s ease;
}

.product .addbtn svg:hover {
    background-color: var(--col1);
    color: white;
    transform: scale(1.2);
    cursor: pointer;
}

.product .addbtn .qty{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.128);
    position: relative;
}


.product .closebtn1{
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    background-color: var(--col1);
    color: green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 100;
    border: none;
    line-height: 0px;
    cursor: pointer;
}

.product .addbtn .qty .qtybtn{
    width: 10px;
    border: none;
    background-color: white;
    
    color: var(--col2);
    font-size: 15px;
    font-weight: 500;
    border-radius: 50%;
    transition: all 0.5s ease-in-out;
}

.product .addbtn .qty p{
    font-size: 15px;
    font-weight: 500;
    color: var(--col2);
    margin: 0px;
    padding: 0px;
}

.product .addtocart{
    border: none;
    background-color: var(--col1);
    color: white;
    padding: 5px;
    border-radius: 5px;
}
@media (max-width: 767px) {
    .product {
        max-width: 100%; /* Adjusted max-width for smaller screens */
    }
}

.expand-look{
    background-color: #02B290;
    color: white;
    padding: 6px;
    border-radius: 50%;
    float: right;
}
.calc-box{
    background-color: #F3F5F9;
    display: flex;
    justify-content: space-evenly;
    padding: 7px 0;
    border-radius: 24px;
}
.image-hover-effect {
    transition: transform 0.2s, transform-origin 0.2s;
}

.image-hover-effect:hover img {
  transform: scale(1.1);
  transform-origin: center;
}
.pop-responsive{
  overflow: scroll;
  max-height: 600px;
  width: 85%;
}

.pop-responsive::-webkit-scrollbar {
  display: none;
}
.cart-box{
  background-color: #02B290;
  display: flex;
  justify-content: center;
  color: white;
  padding: 12px 0;
  margin: 15px 0;
  border-radius: 6px;
}
.pop-box{
  display: flex;
  justify-content: center;
  border: 0.5px solid rgb(201, 201, 201);
  padding: 12px 0;
  border-radius: 6px;
}
.tag-box{
  border: 0.5px solid grey;
  padding: 2px  10px;
  border-radius: 4px;
}
.multi-carousel-container {
    position: relative;
  }
  
  .slider-container {
    position: relative;
  }
  
  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .custom-prev {
    left: 10px; 
    z-index: 9999;
  }
  
  .custom-next {
    right: 10px; 
    z-index: 9999;
  }
  .slick-slide > div > div{
    display: flex !important;
    justify-content: center !important;
  }
  
@media (max-width: 600px) {
  .pop-responsive{
    overflow: scroll;
    max-height: 80%;
    width: 90%;

  }
  .pop-box{
    width:50%;
  }
  
}